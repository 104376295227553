import NavMenu, { SubMenu } from './navigation/NavMenu'
import AppPageRoutes from './AppPageRoutes'
import {
  AppContextProvider,
  useAppContext,
} from '../contextproviders/AppContext'
import { useAuthContext } from '../contextproviders/AuthContext'
import LoadableContent from './shared/LoadableContent'
import { ToastContainer } from 'react-toastify'
import { CostsPageContextProvider } from 'contextproviders/CostsPageContext'
import { QualityPageContextProvider } from 'contextproviders/QualityPageContext'
import { TasksPageContextProvider } from 'contextproviders/TasksPageContext'

function AppMain() {
  const authContext = useAuthContext()
  return (
    <AppContextProvider authContext={authContext}>
      <div className="App">
        {authContext.currentUser && <AppMainContent />}
        <ToastContainer closeButton={false} />
      </div>
    </AppContextProvider>
  )
}

function AppMainContent() {
  const { isFetchingData, selectedCustomer, selectedSiteId } = useAppContext()
  return (
    <CostsPageContextProvider
      selectedCustomer={selectedCustomer}
      selectedSiteId={selectedSiteId}
    >
      <TasksPageContextProvider
        selectedCustomer={selectedCustomer}
        selectedSiteId={selectedSiteId}
      >
        <QualityPageContextProvider
          selectedCustomer={selectedCustomer}
          selectedSiteId={selectedSiteId}
        >
          <LoadableContent isLoading={isFetchingData} withAppBrand>
            <header className="App-header box p-0 pb-5">
              <NavMenu />
              <SubMenu />
            </header>

            <div className="App-main">
              <main className="container is-fluid">
                <AppPageRoutes />
              </main>
            </div>
          </LoadableContent>
        </QualityPageContextProvider>
      </TasksPageContextProvider>
    </CostsPageContextProvider>
  )
}

export default AppMain
