import ContentBox from '../../shared/ContentBox'
import React, { useEffect } from 'react'
import TasksTable from './TasksTable'
import {
  useTasksPageContext,
} from '../../../contextproviders/TasksPageContext'
import LoadableContent from '../../shared/LoadableContent'
import { PageTitle } from 'components/shared/pageTitle'

function TasksPage() {
  return <TasksPageContent />
}

function TasksPageContent() {
  const { isFetchingData, checkForUpdates } = useTasksPageContext()

  useEffect(() => {
    checkForUpdates()
  }, [])
  return (
    <LoadableContent isLoading={isFetchingData}>
      <PageTitle titleId="Tasks" />
      <ContentBox>
        <TasksTable />
      </ContentBox>
    </LoadableContent>
  )
}

export default TasksPage
