import React, { useEffect, useState } from 'react'
import { OPTION_ALL } from '../helper/constants'
import useCostsSummary from '../hooks/useCostsSummary'

export const CostsPageContext = React.createContext()

export const CostsPageContextProvider = ({
  selectedCustomer,
  selectedSiteId,
  children,
}) => {
  const costs = useCostsSummary(
    selectedCustomer?.id,
    selectedSiteId,
    selectedCustomer?.level
  )
  const [isFetchingData, setIsFetchingData] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  useEffect(() => {
    if (selectedCustomer && selectedSiteId) {
      fetchCostsData()
    }
    setIsInitialLoad(false)
  }, [selectedCustomer])

  useEffect(() => {
    // When selected site is changed -> fetch data from REST API
    if (!isInitialLoad && selectedCustomer && selectedSiteId) {
      fetchCostsData()
    }
  }, [selectedSiteId])

  useEffect(() => {
    // Only fetch data again when selected customer has changed and selected site equals OPTION_ALL
    // this will only trigger when selectedSiteId equals OPTION_ALL before and after selected customer change
    // because selected customer change precedes selected site change
    if (!isInitialLoad && selectedCustomer && selectedSiteId === OPTION_ALL) {
      fetchCostsData()
    }
  }, [selectedCustomer])

  const checkForUpdates = async () => {
    setIsFetchingData(true)
    try {
      await costs.checkForUpdates()
    } catch (e) {
      console.error(e)
    }
    setIsFetchingData(false)
  }
  const fetchCostsData = async () => {
    setIsFetchingData(true)
    try {
      await costs.reload()
    } catch (e) {
      console.error(e)
    }
    setIsFetchingData(false)
  }

  const values = {
    isFetchingData,
    costs,
    checkForUpdates,
  }

  return (
    <CostsPageContext.Provider value={values}>
      {children}
    </CostsPageContext.Provider>
  )
}

export const useCostsPageContext = () => {
  return React.useContext(CostsPageContext)
}
