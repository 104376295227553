import React, { useEffect, useState } from 'react'

export const MultiCheckbox = ({
  options = [],
  label = '',
  onChange,
  handleDelete,
}) => {
  const values = options.reduce(
    (acc, cur, i) => ({
      ...acc,
      [i]: { option: cur, check: true },
    }),
    {}
  )
  const [checked, setChecked] = useState(values)

  useEffect(() => {
    setChecked(values)
  }, [options])

  const handleOnChange = (i) => (event) => {
    const newValues = {
      ...checked,
      [i]: { option: checked[i].option, check: !checked[i].check }, // Toggle 'check'
    }
    if (checked[i].check) {
      handleDelete(checked[i].option.value)
    } else {
      const newOptions = Object.keys(newValues).reduce((acc, curr) => {
        if (newValues[curr].check) {
          return [...acc, newValues[curr].option]
        } else {
          return acc
        }
      }, [])
      onChange(newOptions)
    }
    setChecked(newValues)
  }

  const mapLabel = (label) => {
    switch (label) {
      case 'Siivous':
        return 'Siivous- ja tukipalvelut'
      default:
        return label
    }
  }
  return (
    <div style={{ margin: '0.5rem 0' }}>
      {label}
      {Object.keys(checked).length > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {options.map((option, i) => (
            <Checkbox
              key={i}
              label={mapLabel(option.label)}
              value={checked[i]?.check}
              onChange={(e) => handleOnChange(i)(e)}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label style={{ display: 'flex', alignItems: 'center' }}>
      <input
        style={{
          width: '1.75rem',
          height: '1.75rem',
          margin: '0.5rem 0.5rem 0.5rem 0',
        }}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      {label}
    </label>
  )
}
