import { useState } from 'react'
import { getDataFromResponse, getSitePathParam } from '../helper/axios.client'
import { errorFlag } from '../helper/messages'
import i18n from 'i18next'
import { transformDateToValueOf } from '../helper/dates'
import {
  TRAFFIC_LIGHT_THRESHOLDS,
  CATEGORY_TO_MAIN_CATEGORY,
} from '../helper/constants'
import { useApiClientWithInterceptor } from 'helper/axiosInterceptor'

function useQuality(customerId = '', siteId = '', level = 2) {
  const client = useApiClientWithInterceptor('quality/ratings/')
  const permissionsClient = useApiClientWithInterceptor('')
  const [data, setData] = useState([])

  const getQuality = async () => {
    const siteIdParam = getSitePathParam(siteId)
    return await client.get(`/${level}/${customerId}${siteIdParam}`)
  }

  const checkForUpdates = async () => {
    try {
      await permissionsClient.get('/update')
    } catch (e) {
      if (e.response?.status !== 409) {
        console.error(e)
        errorFlag(i18n.t('Error fetching data'))
      }
    }
  }

  const reload = async () => {
    setData([])
    try {
      const response = await getQuality()
      let data = getDataFromResponse(response.data.data, 'quality_ratings')
      // Transform the data so that it would be done just once and not in every component
      data = transformDateToValueOf(data)
      data = data.map((d) => ({
        ...d,
        reportType: CATEGORY_TO_MAIN_CATEGORY[d.reportType] || null,
      }))
      data = data.map((d) => {
        const newData = Object.assign({}, d, {
          isWarning: false,
          isError: false,
        })
        const category = d.reportType
        if (
          TRAFFIC_LIGHT_THRESHOLDS[category] &&
          TRAFFIC_LIGHT_THRESHOLDS[category].reverse
        ) {
          newData.isWarning =
            d.rating > TRAFFIC_LIGHT_THRESHOLDS[category].yellow &&
            d.rating <= TRAFFIC_LIGHT_THRESHOLDS[category].red
          newData.isError = d.rating > TRAFFIC_LIGHT_THRESHOLDS[category].red
        }
        if (
          TRAFFIC_LIGHT_THRESHOLDS[category] &&
          !TRAFFIC_LIGHT_THRESHOLDS[category].reverse
        ) {
          newData.isWarning =
            d.rating < TRAFFIC_LIGHT_THRESHOLDS[category].green &&
            d.rating >= TRAFFIC_LIGHT_THRESHOLDS[category].yellow
          newData.isError =
            d.rating <= TRAFFIC_LIGHT_THRESHOLDS[category].yellow
        }

        return newData
      })
      setData(data)
    } catch (e) {
      if (e.response?.status !== 409) {
        console.error(e)
        errorFlag(i18n.t('Error fetching data'))
      }
    }
  }

  return { data, reload, checkForUpdates }
}

export default useQuality
