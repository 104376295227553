import React, { useEffect } from 'react'
import ContentBox from '../../shared/ContentBox'
import { useAppContext } from '../../../contextproviders/AppContext'
import {
  CostsPageContextProvider,
  useCostsPageContext,
} from '../../../contextproviders/CostsPageContext'
import LoadableContent from '../../shared/LoadableContent'
import CarbonFootprintSummary from './CarbonFootprintSummary'
import { PageTitle } from 'components/shared/pageTitle'
import CarbonFootprintTextContent2022 from './TextContent/CarbonFootprint2022'
import moment from 'moment'
import { useState } from 'react'
import CarbonFootprintTextContent2023 from './TextContent/CarbonFootprint2023'
import { YearSelection } from 'components/shared/YearSelection'

function CarbonFootprintPage() {
  return <CarbonFootprintPageContent />
}

const CarbonFootprintHeader = ({ years, chosenYear, setChosenYear }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <PageTitle titleId="Carbon footprint" />
      <YearSelection
        years={years}
        chosenYear={chosenYear}
        setChosenYear={setChosenYear}
      />
    </div>
  )
}

function CarbonFootprintPageContent() {
  const currentYear = moment().year()
  const [chosenYear, setChosenYear] = useState(currentYear - 1)
  const { isFetchingData, checkForUpdates } = useCostsPageContext()

  useEffect(() => {
    checkForUpdates()
  }, [])
  return (
    <LoadableContent isLoading={isFetchingData}>
      <CarbonFootprintHeader
        years={[currentYear - 2, currentYear - 1]}
        chosenYear={chosenYear}
        setChosenYear={setChosenYear}
      />
      <CarbonFootprintSummary chosenYear={chosenYear} />
      <ContentBox>
        {chosenYear === 2022 ? (
          <CarbonFootprintTextContent2022 />
        ) : (
          <CarbonFootprintTextContent2023 />
        )}
      </ContentBox>
    </LoadableContent>
  )
}

export default CarbonFootprintPage
