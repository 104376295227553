import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { GlobalSearchFilter } from '../../shared/GlobalSearchFilter'
import DatePicker from 'react-datepicker'
import fi from 'date-fns/locale/fi'

import Select from '../../shared/form/Select'

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <div
    onClick={onClick}
    className="input has-icons-right is-flex is-justify-content-space-between is-clickable"
    placeholder="Aikaikkuna"
  >
    <span>{value}</span>
    <span className="icon is-right has-text-primary">
      <i className="fa fa-calendar" />
    </span>
  </div>
))

const TasksTableFilters = ({
  searchFilter,
  setSearchFilter,
  startDate,
  endDate,
  minDate,
  maxDate,
  onDateChange,
  completedStartDate,
  completedEndDate,
  onCompletedDateChange,
  serviceValue,
  serviceOptions,
  handleServiceChange,
  typeValue,
  typeOptions,
  handleTypeChange,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="columns is-multiline">
        <div className="column is-one-quarter-widescreen is-half-desktop">
          <label className="label" id="tasks-date-picker-label">
            {t('Date short')}
          </label>
          <DatePicker
            placeholderText="Valitse aikaikkuna"
            ariaLabelledBy="tasks-date-picker-label"
            locale={fi}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onDateChange}
            className="select"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            calendarClassName="lt-calendar"
            customInput={<DateInput />}
          />
        </div>
        <div className="column is-one-quarter-widescreen is-half-desktop">
          <label className="label" id="tasks-completed-date-picker-label">
            {t('Resolved')}
          </label>
          <DatePicker
            placeholderText="Pick a timeframe"
            ariaLabelledBy="tasks-completed-date-picker-label"
            locale={fi}
            selectsRange
            startDate={completedStartDate}
            endDate={completedEndDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onCompletedDateChange}
            className="select"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            calendarClassName="lt-calendar"
            customInput={<DateInput />}
          />
        </div>
        <div className="column is-one-quarter-widescreen is-half-desktop">
          <Select
            label={t('Service')}
            placeholder={serviceOptions[0].label}
            defaultValue={serviceOptions[0]}
            options={serviceOptions}
            selected={serviceValue}
            onChange={(event) => handleServiceChange(event)}
          />
        </div>
        <div className="column is-one-quarter-widescreen is-half-desktop">
          <GlobalSearchFilter
            filter={searchFilter}
            setFilter={setSearchFilter}
            label={t('Search for a task')}
          />
        </div>
      </div>
    </>
  )
}

export default TasksTableFilters
