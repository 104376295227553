import axios from 'axios'
import { AUTH_PROXY, BACKEND_API_URL } from '../config'
import { error, response } from './intercepters'
import { OPTION_ALL } from './constants'

axios.defaults.withCredentials = true

export function getAuthClient(uri = '') {
  const client = axios.create({
    baseURL: `${AUTH_PROXY}/${uri}`,
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })
  client.interceptors.response.use(response, error)

  return client
}

export function getAPIClient(uri) {
  const client = axios.create({
    baseURL: `${BACKEND_API_URL}/${uri}`,
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })
  client.interceptors.response.use(response, error)

  return client
}

// Helper functions for HTTPS requests
export function getSitePathParam(siteId) {
  return siteId !== OPTION_ALL ? `/${siteId}` : ''
}

export function getDataFromResponse(data, key = '') {
  return data && data[key] ? data[key] : []
}

export function convertFieldValueIfValueMatches(
  data = [],
  field = '',
  valueToMatch = '',
  newValue = ''
) {
  if (data.length < 1) {
    return data
  }

  for (const i in data) {
    if (data[i][field] === valueToMatch) {
      data[i][field] = newValue
    }
  }
  return data
}
